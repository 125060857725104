// @ts-nocheck
import * as $ from 'pumpkin.js'

let masks = [];

const InvalidInputHelper = (input) => {
  // setAttributes
  input.required = true;
  input.setAttribute('pattern', '\\+7[\\(]{0,1}\\d{1}[0-9]{2}[\\)]{0,1}\\d{3}[-]{0,1}\\d{2}[-]{0,1}\\d{2}');
  input.setAttribute('autocomplete', 'off');

  input.addEventListener('input', (e) => {
    e.target.setCustomValidity('')
  });

  input.addEventListener('invalid', (e) => {
    e.target.setCustomValidity('Формат ввода телефона без 8')
  })
};

// удаление маски после выделения и нажатия del или backspace
const deleteMaskOnSelection = (input, i) => {

    input.addEventListener('keydown', function() {
        let key = event.keyCode || event.charCode;
        if (this.selectionStart === 0 && this.selectionEnd === this.value.length && ( key == 8 || key == 46 )) {
            masks[i].unmaskedValue = '';
        }
    });

};

const phoneMask = () => {
  import('imask').then(
    ({ default: IMask }) => {
      // First remove [novalidate]
      const formCF7 = $.qsa('.wpcf7-form'),
        $el = $.qsa('.js-tel'),
        options = {
          mask: '+{7}(000)000-00-00',
          prepare: (appended, masked) => {
            if (appended === '8' && masked.value === '') {
              return '';
            }
            return appended;
          },
          lazy: true,
        };

      if(formCF7.length) {
        ;[...formCF7].forEach(qs => {
          qs.noValidate = false
        })
      }

      ;[...$el].forEach((qs, i) => {
          masks[i] = new IMask(qs, options);
          InvalidInputHelper(qs);
          deleteMaskOnSelection(qs, i)
      })
    }
  )
};

export default phoneMask
