// @ts-nocheck
import * as $ from 'pumpkin.js'

class Modal {
  constructor(markup) {
    this.hasMarkup = markup && typeof markup === 'object'
    if (this.hasMarkup) {
      this.modal = $.qs(markup.querySelector || 'form')
    }
  }
  create(cb) {
    if (!this.hasMarkup) document.body.appendChild(this.modal)

    $.on('click', this.modal, e => {
      if (e.target === this.modal) {
        e.preventDefault()
        this.toggleModal()
      }
    })

    $.on('click', this.modal, '.js-close', e => {
      e.preventDefault()
      this.closeModal()
    })

    $.on('keydown', document, e => {
      if (
        !this.modal.classList.contains('pointer-events-none') &&
        !this.modal.classList.contains('opacity-0')
      ) {
        if (e.keyCode === 27) this.closeModal()
      }
    })

    if (cb) cb()
  }
  toggleModal() {
    this.modal.classList.toggle('pointer-events-none')
    this.modal.classList.toggle('opacity-0')
    document.body.classList.toggle('overflow-hidden')
  }

  openModal() {
    this.modal.classList.remove('pointer-events-none')
    this.modal.classList.remove('opacity-0')
    document.body.classList.add('overflow-hidden')
  }

  closeModal() {
    this.modal.classList.add('pointer-events-none')
    this.modal.classList.add('opacity-0')
    document.body.classList.remove('overflow-hidden')
  }
}

export default Modal
