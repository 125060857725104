// @ts-nocheck
import * as $ from 'pumpkin.js'
import Glider from 'glider-js'

export const slider = {
  selector: [...$.qsa('.about__slider')],
  options: {
    slidesToShow: 1,
    slidesToScroll: 1,
  },

  hideFFScrollBars(e) {
    const scrollbarHeight = 17
    if(/firefox/i.test(navigator.userAgent)){
      // We only need to appy to desktop. Firefox for mobile uses
      // a different rendering engine (WebKit)
      if (window.innerWidth > 575){
        e.target.parentNode.style.height = (e.target.offsetHeight - scrollbarHeight) + 'px'
      }
    }
  },

  init() {
    this.selector.forEach.call(this.selector, item => {
      this.slider = new Glider(item, this.options)

      // Arrows
      this.slider.setOption({
        arrows: {
          prev: `#glider-prev-${item.dataset.slider}`,
          next: `#glider-next-${item.dataset.slider}`
        }
      })

      this.slider.refresh(true)

      document.addEventListener('glider-loaded', this.hideFFScrollBars)
      document.addEventListener('glider-refresh', this.hideFFScrollBars)
    })
  },

  destroy() {
    this.selector.forEach.call(this.selector, () => {
      if (typeof this.slider === 'undefined') return;
      this.slider.destroy()
    })
  },
}
