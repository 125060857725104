// @ts-nocheck
import * as $ from 'pumpkin.js'
import { LuminousGallery, Luminous } from 'luminous-lightbox'

const images = () => {
  if ('loading' in HTMLImageElement.prototype) {
    const images = $.qsa("img.lazyload")
    images.forEach(img => {
      img.src = img.dataset.src
    })
  } else {
    // Dynamic import for Lazysizes
    import('lazysizes').then(
      ({ default: lazySizes }) => {
        lazySizes.init()

        document.addEventListener('lazyloaded', e => {
          import('object-fit-images').then(
            ({ default: objectFitImages }) => {
              objectFitImages(e.target)
            }
          )
        })
      }
    )
  }

  /*import('luminous-lightbox').then(
    ({ default: LuminousGallery }) => {
      console.log('ok')
      console.log(...document.querySelectorAll('[data-lightbox]'))

      new LuminousGallery(
        ...document.querySelectorAll('[data-lightbox]'), {
            arrowNavigation: true
          }, {}
      )
    }
  )*/

  /*
  new LuminousGallery(
    document.querySelectorAll('.lightbox'), {
      arrowNavigation: true
    }, {
      caption: (trigger) => {
        return trigger.querySelector('img').getAttribute('alt')
      }
    }
  )
  */
  document.querySelectorAll(".lightbox").forEach((item) => { new Luminous(item) })
}

export default images
