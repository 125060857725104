// @ts-nocheck
import * as $ from 'pumpkin.js'

const smoothScroll = ($id) => {
  if ($.qs($id)) {
    document.querySelector($id).scrollIntoView({ behavior: 'smooth' })
  }
}

export default smoothScroll
