// @ts-nocheck
import * as $ from 'pumpkin.js'
import { IdleQueue } from 'idlize/IdleQueue.mjs'

import Modal from '../lib/modal'

const modals = () => {
  const queue = new IdleQueue()

  let $bookYourTableModal, $bookExcursionModal
  const $bookTableBtn = $.qsa('.js-book-table')
  const $bookExcursionBtn = $.qsa('.js-book-excursion')
  const $burger = $.qs('.hamburger')
  const $panel = $.qs('.panel-cover')

  if($bookTableBtn.length) {
    queue.pushTask(() => {
      $bookYourTableModal = new Modal({
        querySelector: '.jsBookTable'
      })
      $bookYourTableModal.create()
    })

    $.on('click', $bookTableBtn, e => {
      e.preventDefault()
      queue.pushTask(() => {
        $bookYourTableModal.openModal()
        if ($panel) $panel.classList.remove('active')
        if ($burger) $burger.classList.remove('is-active')
      })
    })
  }

  if($bookExcursionBtn.length) {
    queue.pushTask(() => {
      $bookExcursionModal = new Modal({
        querySelector: '.jsBookExcursion'
      })
      $bookExcursionModal.create()
    })

    $.on('click', $bookExcursionBtn, e => {
      e.preventDefault()
      queue.pushTask(() => {
        $bookExcursionModal.openModal()
        if ($panel) $panel.classList.remove('active')
        if ($burger) $burger.classList.remove('is-active')
      })
    })
  }
}

export default modals
