// @ts-nocheck
import * as $ from 'pumpkin.js'
import smoothScroll from '../lib/smoothScroll'

const about = () => {
  const $scrollTo = $.qsa('[data-scroll-to]')

  if($.qs('.about__slider')) {
    import('../lib/gliderJs').then(
      gliderJs => gliderJs.slider.init()
    ).catch(
      e => console.error(`${e.name} : ${e.message}`)
    )
  }

  if ($scrollTo.length) {
    ;[...$scrollTo].forEach(qs => {
      //qs.dataset.scrollTo
      $.on('click', qs, e => {
        e.preventDefault()
        // Polyfilled smooth scolling
        smoothScroll(e.target.dataset.scrollTo)
      })
    })
  }
}

export default about
