// @ts-nocheck
const fixIos = () => {
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        let els = document.getElementsByTagName('a')
        for (var i = 0; i < els.length; i++) {
            els[i].addEventListener('touchend', () => {});
        }
    }
}

export default fixIos
