// @ts-nocheck
import * as $ from 'pumpkin.js'

const mobileNav = () => {
  import('../lib/isMobile').then(
    ({ default: isMobile }) => {
      // Only on Mobile devices
      if( isMobile ) {
        const $burger = $.qs('.hamburger')
        const $panel = $.qs('.panel-cover')
        const el = document.body
        const html = el.parentNode

        if( $burger && $panel ) {
          $burger.addEventListener('click', (e) => {
            e.preventDefault()
            el.classList.toggle('with-panel-left-cover')
            $burger.classList.toggle('is-active')
            $panel.classList.toggle('active')
            $panel.style.display = 'block'
          })

          html.addEventListener('click', (e) => {
            if ( !$burger.contains(e.target) ) {
              setTimeout(() => {
                $panel.classList.remove('active')
                $panel.style.removeProperty('display')
                $burger.classList.remove('is-active')
                el.classList.remove('with-panel-left-cover')
              }, 400)
            }
          })
        }

        // Categories scroll
        // можно заменить на выставление свойства autofocus при рендере
        let category = $.qs(`a[href="${window.location.pathname}"]`);
        if (category) {
          category.scrollIntoView({inline: 'center', behavior:'instant'});
        }
      }
    }
  )
}

export default mobileNav
