// @ts-nocheck
const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const { Workbox } = await import('workbox-window')

    const wb = new Workbox('/app/themes/kebab-theme/assets/sw.js')
    wb.register()
  }
}

export default registerServiceWorker
