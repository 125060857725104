// @ts-nocheck
import 'url-search-params-polyfill'
// import Promise from 'promise-polyfill'
import smoothscroll from 'smoothscroll-polyfill'
import requestFrame from 'request-frame'
import fixIos from '../lib/fixIos'

const svgSupportsExternalSource = () => {
  const newerIEUA = /\bTrident\/[567]\b|\bMSIE (?:9|10)\.0\b/, webkitUA = /\bAppleWebKit\/(\d+)\b/, olderEdgeUA = /\bEdge\/12\.(\d+)\b/;
  return newerIEUA.test(navigator.userAgent) || (navigator.userAgent.match(olderEdgeUA) || [])[1] < 10547 || (navigator.userAgent.match(webkitUA) || [])[1] < 537 ? false : true;
}

const featureDetect = () => {
  // if (
  //   typeof window.Promise !== 'undefined' &&
  //   window.Promise.toString().indexOf('[native code]') !== -1
  // ) {
  //   window.Promise = Promise
  // }

  // iOS Double Tap fix
  fixIos();

  // Smooth Scroll + requestAnimationFrame
  if (
    typeof window.requestAnimationFrame !== 'undefined' &&
    window.requestAnimationFrame.toString().indexOf('[native code]') !== -1 &&
    typeof window.cancelAnimationFrame !== 'undefined' &&
    window.cancelAnimationFrame.toString().indexOf('[native code]') !== -1
  ) {
    requestFrame('native')
  }
  smoothscroll.polyfill()

  // SVG Polyfill
  if(!svgSupportsExternalSource()) {
    import('svg4everybody').then(
      ({ default: SVG4Everybody }) => {
        SVG4Everybody({
          nosvg: false,
          polyfill: true
        })
      }
    )
  }
}

export default featureDetect
