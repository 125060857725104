// @ts-nocheck
'use strict'

import * as $ from 'pumpkin.js'

//import 'core-js/stable'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
//import 'core-js/modules/es.promise'
//import 'core-js/modules/es.array.iterator'

import featureDetect from './modules/feature-detect'
import changePhone from './modules/changePhone'
import images from './modules/images'
import about from './modules/about'
import modals from './modules/modals'
import phoneMask from './modules/phoneMask'
import mobileNav from './modules/mobile-nav'
import mobileCard from './modules/mobile-card'
import performance from './modules/performance'
import registerServiceWorker from './modules/sw-loader'

$.ready(() => {
  featureDetect(),
  changePhone(),
  images(),
  modals(),
  about(),
  phoneMask(),
  mobileNav(),
  mobileCard(),
  performance(),
  registerServiceWorker()
})
