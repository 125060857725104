// @ts-nocheck
import * as $ from 'pumpkin.js'

const performance = () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    import('intersection-observer').then(
      init
    )
  } else init()
}

const init = () => {
  import('quicklink').then(
    ({ listen }) => {
      const start = (options = {}) => {
        options = {
          ignores: [
            (uri, elem) => String(elem.getAttribute('href'))[0] === '#',
            (uri, elem) => elem.matches('[noprefetch]') || elem.closest('[noprefetch]'),
          ],
          ...options,
        }
        listen(options)
      }

      $.ready(start)

      //document.addEventListener('turbolinks:load', start)
      document.addEventListener('quicklink', (e) => { start(e.detail || {}) })
    }
  )
}

export default performance
