// @ts-nocheck
import * as $ from 'pumpkin.js'

const mobileCard = () => {
  import('../lib/isMobile').then(
    ({ default: isMobile }) => {
      // Only on Mobile devices
      if( isMobile ) {
        const $btn = $.qsa('.info-item')

        $btn.forEach( button => {
          button.addEventListener('click', e => {
            e.preventDefault()

            // Data toggle id
            const $toggle = button.dataset.toggle
            const $togglePanel = $.qs('[data-id="' + $toggle + '"')

            if ( !$togglePanel.classList.contains('active') ) {
              $togglePanel.classList.add('active')
              $togglePanel.style.height = 'auto'

              const toggleHeight = $togglePanel.clientHeight + 'px'
              $togglePanel.style.height = '0px'

              setTimeout( () => {
                $togglePanel.style.height = toggleHeight
              }, 0)
            } else {
              $togglePanel.style.height = '0px'

              $togglePanel.addEventListener('transitionend', () => {
                $togglePanel.classList.remove('active')
              }, {
                once: true
              })
            }
          })
        })
      }
    }
  )
}

export default mobileCard
